import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ListItemText from '@material-ui/core/ListItemText';

interface IProperties {
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        listItem: {
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '4px',
            marginBottom: '5px',
        },
        listExpanderText: {
            fontWeight: 100,
        },
        listExpander: {
            backgroundColor: '#EEEEEE',
            boxShadow: 'none',
        },
        ListExpanderDetail: {
            padding: '8px 24px 0px 24px',
        },
        ListItemText: {
            fontSize: '0.75em',
            margin: '10px',
            "@media (max-width: 500px)": {
                display: 'none',
            }
        }
    }),
);

export function LastSync(props: IProperties) {
    const classes = useStyles();
    const [lastSync, setLastSyncState] = useState<Date | null>(null);


    const callLastSync = () => {
        axios
            .get(`${props.url}/api/v1/sync/lastsync`)
            .then((response: any) => {
                var date = new Date(response.data.lastSync);
                if (isNaN(date.getTime())) { setLastSyncState(null) } else { setLastSyncState(date) }
            })
            .catch((error: any) => {
                setLastSyncState(null);
            })
    };

    useEffect(() => {
        const interval = setInterval(() => {
            callLastSync();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        callLastSync();
    }, []);

    return <ListItemText classes={{ secondary: classes.ListItemText }} secondary={generateOfflineDays(lastSync)} /> 

}

function generateOfflineDays(lastSync: Date | null): React.ReactNode {
    if (lastSync === null)
    {
        return "";
    }
    else
    {
        var days = Math.round((lastSync.getTime() - new Date().getTime()) / (1000 * 3600 * (-24)));

        if ((lastSync.getTime() - new Date().getTime()) / (1000 * 3600 * (-24)) <= 1 && (lastSync.getTime() - new Date().getTime()) / (1000 * 3600 * (-24)) > 0.5) {
            return "Seit einem Tag offline"
        }
        else if (days > 1) {
            return `Seit ${days} Tagen offline`
        }
        else {
            return "";
        }
    }
}
