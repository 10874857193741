import { createGlobalStyle } from "styled-components";
import { NavBar } from '../navbar/navbar';
import * as React from 'react';
import { SyncStateList } from "../sync-state";
import { Container } from "@material-ui/core";

const GlobalStyle = createGlobalStyle`
    html,
    body,
    #root {
        height: 100%;
        margin: 0;
        background-color: #EEEEEE;
    }
`;

export function App() {
    return (
        <div>
            <GlobalStyle />
            <NavBar />
            <Container maxWidth="md">
                <SyncStateList />
            </Container>
        </div>
    );
}
