import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import * as React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginBottom: '10px'
        },
    }),
);

export function NavBar() {
    const classes = useStyles();

    return (
        <div>
            <AppBar className={classes.root} position="static">
                <Toolbar>
                    <Typography variant="h6" color="inherit">pg_monitoring</Typography>
                </Toolbar>
            </AppBar>
        </div>
    );
}
