import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ListItemText from '@material-ui/core/ListItemText';

interface IProperties {
    url: string;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        listItem: {
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '4px',
            marginBottom: '5px',
        },
        listExpanderText: {
            fontWeight: 100,
        },
        listExpander: {
            backgroundColor: '#EEEEEE',
            boxShadow: 'none',
        },
        ListExpanderDetail: {
            padding: '8px 24px 0px 24px',
        },
        ListItemText: {
            fontSize: '0.75em',
        }
    }),
);

export function GraveState(props: IProperties) {
    const classes = useStyles();
    const [graveStats, setGraveStats] = useState(0);


    const callGraveCountStats = () => {
        axios
            .get(`${props.url}/api/v1/grab/anzahl`)
            .then((response: any) => {
                setGraveStats(response.data.anzahl);
            })
            .catch((error: any) => {
                setGraveStats(0);
            })
    };

    useEffect(() => {
        callGraveCountStats();
    }, [callGraveCountStats]);

    return <ListItemText classes={{ secondary: classes.ListItemText }} primary="Anzahl Gräber:" secondary={graveStats} />

}