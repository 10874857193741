import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import axios from 'axios';
import Chip from '@material-ui/core/Chip';

interface IProperties {
    url: string;
    appType: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        online: {
            backgroundColor: '#4caf50',
            width: '100px',
            color: '#FFFFFF',
        },
        offline: {
            backgroundColor: '#f44336',
            width: '100px',
            color: '#FFFFFF',
        },
    }),
);

function isApiOnline(url: string, callback: any) {
    // try to load favicon
    var timer = setTimeout(function () {
        // timeout after 5 seconds
        callback(false);
    }, 5000)

    var img = document.createElement("img");
    img.onload = function () {
        clearTimeout(timer);
        callback(true);
    }

    img.onerror = function () {
        clearTimeout(timer);
        callback(false);
    }

    img.src = url + "/images/favicon.ico";
}

function isFriedhofplanOnline(url: string, callback: any) {
    // try to load favicon
    var timer = setTimeout(function () {
        // timeout after 5 seconds
        callback(false);
    }, 5000)

    var img = document.createElement("img");
    img.onload = function () {
        clearTimeout(timer);
        callback(true);
    }

    img.onerror = function () {
        clearTimeout(timer);
        callback(false);
    }

    img.src = url + "/assets/main/favicon.ico";
}

export function SyncState(props: IProperties) {
    const classes = useStyles();
    const [apiResult, setApiResult] = useState('offline');

    const callSyncState = () => {

        switch (props.appType) {
            case 0:
                axios
                    .get(`${props.url}/api/v1/sync/state`)
                    .then((response: any) => {
                        setApiResult(response.data.isOnline === true ? "online" : "offline");
                    })
                    .catch((error: any) => {
                        setApiResult("offline");
                    })
                break;
            case 1:
                isApiOnline(props.url, function (found: any) {
                    if (found) {
                        setApiResult("online");
                    }
                    else {
                        setApiResult("offline");
                    }
                })
                break;
            case 2:
                isFriedhofplanOnline(props.url, function (found: any) {
                    if (found) {
                        setApiResult("online");
                    }
                    else {
                        setApiResult("offline");
                    }
                })
                break;
            default:
        }
    };

    //useEffect(() => {
    //    const interval = setInterval(() => {
    //        callSyncState()
    //    }, 30000);
    //    return () => clearInterval(interval);
    //}, []);

    useEffect(() => {
        callSyncState();
    }, [callSyncState]);

    return <Chip label={apiResult} className={apiResult === "online" ? classes.online : classes.offline} />

}