import * as React from 'react';
import { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import axios from 'axios';
import { SyncState } from './sync-state';
import { LastSync } from './last-sync';
import { GraveState } from './grave-state';
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel';
import MuiExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import MuiExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';

export interface Api {
    id: string;
    url: string;
    name: string;
    appType: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
        },
        listItem: {
            boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
            backgroundColor: theme.palette.background.paper,
            borderRadius: '4px',
            marginBottom: '5px',
            display: 'block',
        },
        listItemSecondaryAction: {
            display: 'flex',
            alignItems: 'center',
        },
        listExpanderText: {
            fontWeight: 100,
        },
        listExpander: {
            backgroundColor: '#EEEEEE',
            boxShadow: 'none',
        },
        ListExpanderDetail: {
            padding: '8px 24px 0px 24px',
        },
        ListItemText: {
            fontSize: '0.75em',
        }
    }),
);

const ExpansionPanel = withStyles({
    root: {
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiExpansionPanel);

const ExpansionPanelSummary = withStyles({
    root: {
        padding: '0',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },
    content: {
        '&$expanded': {
            margin: '0',
        },
    },
    expanded: {},
})(MuiExpansionPanelSummary);

const ExpansionPanelDetails = withStyles((theme) => ({
    root: {
        padding: '0'
    },
}))(MuiExpansionPanelDetails);

export function SyncStateList() {
    const classes = useStyles();

    const [pgSyncResult, setPgSyncResult] = useState<Api[]>([]);
    const [pgApiResult, setPgApiResult] = useState<Api[]>([]);
    const [pgFriedhofsplanResult, setPgFriedhofsplanResult] = useState<Api[]>([]);

    const callPgSync = () => {
        axios
            .get('api/pgsync')
            .then((response: any) => {
                setPgSyncResult(response.data);
            })
    };

    const callPgApi = () => {
        axios
            .get('api/pgapi')
            .then((response: any) => {
                setPgApiResult(response.data);
            })
    };

    const callPgFriedhofsplan = () => {
        axios
            .get('api/pgfriedhofsplan')
            .then((response: any) => {
                setPgFriedhofsplanResult(response.data);
            })
    };

    useEffect(() => {
        const interval = setInterval(() => {
            callPgSync();
            callPgApi();
            callPgFriedhofsplan();
        }, 30000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        callPgSync();
        callPgApi();
        callPgFriedhofsplan();
    }, []);

    return (
        <div>
            <ExpansionPanel className={classes.listExpander} square defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" className={classes.listExpanderText}>pg_sync</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails className={classes.ListExpanderDetail}>
                    <List className={classes.root}>
                        {pgSyncResult.map((x) => (
                            <div key={x.id}>
                                <ListItem className={classes.listItem}>
                                    <ListItemText classes={{ secondary: classes.ListItemText }} primary={x.name} secondary={<Link href={x.url}>{x.url}</Link>} />
                                    <ListItemSecondaryAction className={classes.listItemSecondaryAction}>
                                        <LastSync url={x.url} />
                                        <SyncState url={x.url} appType={x.appType} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                        ))}
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel className={classes.listExpander} square defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" className={classes.listExpanderText}>pg_api</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <List className={classes.root}>
                        {pgApiResult.map((x) => (
                            <div key={x.id}>
                                <ListItem className={classes.listItem}>
                                    <ListItemText classes={{ secondary: classes.ListItemText }} primary={x.name} secondary={<Link href={x.url}>{x.url}</Link>} />
                                    <GraveState url={x.url} />
                                    <ListItemSecondaryAction>
                                        <SyncState url={x.url} appType={x.appType} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                        ))}
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel className={classes.listExpander} square defaultExpanded={true}>
                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h5" className={classes.listExpanderText}>friedhofsplan.de</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <List className={classes.root}>
                        {pgFriedhofsplanResult.map((x) => (
                            <div key={x.id}>
                                <ListItem className={classes.listItem}>
                                    <ListItemText classes={{ secondary: classes.ListItemText }} primary={x.name} secondary={<Link href={x.url}>{x.url}</Link>} />
                                    <ListItemSecondaryAction>
                                        <SyncState url={x.url} appType={x.appType} />
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </div>
                        ))}
                    </List>
                </ExpansionPanelDetails>
            </ExpansionPanel>
        </div>
    );
}